//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'CtaTeaserLead',
    props: ['data'],
    data() {
        return {
            assetPath: this.$config.pimcoreUrl,
        };
    },
    computed: {
        teasers() {
            return this.data.teasers.map((element) => {
                const image = element?.image?.image;

                if (!element.href) return element;

                if (element.isDownloadLink) {
                    element.href.path = `${this.assetPath}${element.href.path}`;
                }
                if (image) {
                    return {
                        ...element,
                        image: this.$mapImageData(image),
                    };
                } else {
                    return element;
                }
            });
        },
    },
};
